const isBrowser = typeof window !== `undefined`

const getUser = () =>
  window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : null

export const setUser = user => {
  return (window.localStorage.gatsbyUser = JSON.stringify(user))
}

export const currentUser = () => {
  return isBrowser && getUser()
}