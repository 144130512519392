import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImgLine from "../../images/modal-line.png"
import InputMask from "react-input-mask"
import { useForm } from "react-hook-form"
import { currentUser } from "../../utils/auth"
import { navigate } from "gatsby"
import { secondsToMinutes } from "../../utils/formatter"

export default function ActivatePage(props) {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    setError,
    clearErrors,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  })


  const user = currentUser()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!user) {
      navigate("/register/")
    }

    if (isSubmitted) {
      navigate("/register/success/")
    }
  })




  const setServerError = (timeout) => {
    setError("result", {
      type: "manual",
      message: "Ошибка сервера. Повторите попытку позже.",
    })

    setTimeout(() => {
      clearErrors("result")
    }, timeout || 3000)
  }


  const onSubmit = form => {
    setLoading(true)
    const details = {
      phone: user.phone,
      code: form.code,
      email: user.email,
      name: user.name,
      site: user.site,
      promoCode: user.promoCode,
      isLargeVolumePackage: user.isLargeVolumePackage
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(details),
    }

    fetch("/api/user/activate", requestOptions)
      .then(response => {

        setLoading(false)

        if (response.ok) {
          return response.json()
            .then(data => {
              if (data.result === "success" && typeof window !== `undefined`) {
                setIsSubmitted(true)
                return
              }
              // status ok, but something failure
              throw Error()
            })
        }

        if (response.status === 422) { // server side validation errors
          return response.json()
            .then(errors => {
              for (const error in errors) {
                if (errors.hasOwnProperty(error)) {
                  setError(error, {
                    type: "manual",
                    message: errors[error],
                  })
                }
              }
            })
        }

        // unexpected server errors
        throw Error()

      })
      .catch(() => setServerError()) // catch all server errors
  }

  const initialResendDelay = 300
  const [resendDelay, setResendDelay] = useState(initialResendDelay)

  React.useEffect(() => {
    if (resendDelay > 0) {
      setTimeout(() => setResendDelay(resendDelay - 1), 1000)
    }
  })

  const resendCode = () => {
    const details = {
      phone: user.phone,
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(details),
    }

    fetch("/api/user/resend", requestOptions)
      .then(response => {

        if (response.ok) {
          return response.json()
            .then(data => {
              if (data.result === "success") {
                setResendDelay(initialResendDelay)
              }
            })
        }

        // unexpected server errors
        throw Error()

      })
      .catch((e) => setServerError(300, e)) // catch all server errors
  }

  const { isValid } = formState

  return (
    <Layout location={props.location}>
      <SEO title="Стрекоза"/>
      <section className="register-content">
        <div className="container">
          <>
            <div className="register-box">
              <div className="register__head">
                <h2>Подтверждение номера телефона</h2>
                <img className="register__head_img" src={ImgLine} alt=""/>
              </div>
              <form className="register-form" onSubmit={handleSubmit(onSubmit)}>

                <div className={`register-form__field ${errors.phone ? "has-error" : ""}`}>
                  <label htmlFor="ct_tel" className="register-form__label">
                    На ваш номер {user.phone} отправлено SMS с кодом
                  </label>
                  <InputMask
                    mask="999999"
                    alwaysShowMask
                    name="code"
                    type="text"
                    className="register-form__input"
                    id="ct_tel"
                    ref={register({
                      required: "Необходимо заполнить",
                      pattern: {
                        value: /(\d{6})/,
                      },
                    })}
                  />
                  <div className="formErrors">
                    <p className="register-form__error">
                      {errors.code?.message}
                    </p>
                    <p className="register-form__error">
                      {errors.result?.message}
                    </p>
                  </div>

                  <label className="register-form__label">
                    {resendDelay > 0
                      ? (
                        <span className="timer">
                        <br/>
                        Повторная отправка возможна через <b>{secondsToMinutes(resendDelay)}</b>
                        </span>)
                      : (
                        <>
                          <br/>
                          <span className="resend-text" onClick={resendCode} role="presentation">Отправить еще раз</span>
                        </>
                      )}
                  </label>
                </div>

                <button
                  type="submit"
                  className="btn-overall register-form__btn"
                  data-modal="#complete"
                  disabled={!isValid || loading}
                >
                  {loading ? 'Подождите...' :'Подтвердить'}
                </button>
              </form>
            </div>
          </>
        </div>
      </section>
    </Layout>
  )
}

