const formatCurrency = n => {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(n)
}

const formatPercent = n => {
  return new Intl.NumberFormat("ru-RU", {
    style: "decimal",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    useGrouping: false,
  }).format(n)
}

const formatNumber = (n, d = 2, useGrouping = true) => {
  n = Math.round((n + Number.EPSILON) * 100) / 100

  return new Intl.NumberFormat("ru-RU", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: d,
    useGrouping: useGrouping,
  }).format(n)
}

const secondsToMinutes = (seconds) => {
  return (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ":" : ":0") + seconds
}

export { formatCurrency, formatPercent, formatNumber, secondsToMinutes }
